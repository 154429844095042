import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import SimpleList from './contacts.jsx';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const Links = () => {
  const [currentPage, setCurrentPage] = useState('home');

  return (
    <div className="links">
      <header className="App-header">
        <header className="topBar">
          <Button onClick={() => setCurrentPage('home')}>Home</Button>
          <Button onClick={() => setCurrentPage('about')}>About Me</Button>
          <Button component="a" href="/resume.pdf" target="_blank">Resume</Button>
          <Button onClick={() => setCurrentPage('socials')}>Socials</Button>
        </header>
      </header>
      
      <TransitionGroup>
        <CSSTransition key={currentPage} classNames="fade" timeout={500}>
          <div> {/* Add a div here to ensure single child */}
            {currentPage === 'home' && (
              <header className="welcome"> 
                <p>Hey... I'm Alex.</p><small>I like to program stuff.</small>
              </header>
            )}
            {currentPage === 'about' && (
              <header className="welcome">
                <p>I'm a Software Engineer at Google.</p>
                <small>I like to code in C++, Python, Rust and TypeScript.<p></p>
                </small>
              </header>
            )}
            {currentPage === 'socials' && (
              <header className="App-header">
                <header className="socials">
                  <SimpleList />
                </header>
              </header>
            )}
          </div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
}

export default Links;
