import React from 'react';
import logo from './logo.svg';
import './App.css';
import Links from './components/links.js'

document.title = 'Alex Patapan';

function App() {
  return (  
    
    <div className="App">
    
    <video id="myVideo" muted autoplay="true" loop className="background-video">
    <source src="low.mp4" type="video/mp4"/>
    <script>var vid = document.getElementById("myVideo");
    vid.defaultPlaybackRate = 10;</script>

    </video>
    


    <header className="Background">
        <Links />
      </header>
    </div>
  );
}




export default App;

